.sidebar .library-display {
    height: calc(100% - 220px - 16px - 18px - 50px);
    padding: 10px 4%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    overflow-y: auto;
}

.sidebar .library-display h1 {
    margin-bottom: 6px;
    font-size: 1.8rem;
    font-weight: bold;
}

.sidebar .library-display h2.subtitle {
    margin-bottom: 10px;
    font-size: 1.6rem;
    font-weight: bold;
}

.sidebar .library-display h2 {
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: bold;
}

.sidebar .library-display h3 {
    margin-bottom: 4px;
    font-size: 1.2rem;
    font-weight: bold;
}

.sidebar .library-display p {
    margin-bottom: 10px;
    font-size: 1.2rem;
}

.view-library-shell {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.view-library-shell div.carousel-nav {
    position: absolute;
    right: 20px;
}

.view-library-shell div.carousel-nav > div {
    width: 50px;
    margin-left: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.view-library-shell div.carousel-nav > div .my-button a {
    position: relative;
    top: -2px;
    font-weight: bold;
}

.view-library-shell > .my-button {
    margin-top: 20px;
}

.library-display ul {
    margin: 0 0 10px 18px;
    list-style: disc outside;
}

.library-display ul li div.height-4px {
    height: 4px;
}

.library-display ul ul {
    margin: 0 0 4px 26px;
    list-style: circle outside;
}

.sidebar .library-display li {
    margin-bottom: 2px;
    font-size: 1.2rem;
}

.library > div {
    margin-bottom: 16px;
}

.library table {
    margin-bottom: 16px;
    border-spacing: 4px;
    border-collapse: separate;
    font-size: 1.4rem;
}

.library table.armorWeaponTable td {
    padding: 2px 0;
    vertical-align: middle;
    line-height: 1.2;
}

.library table div.tbody-spacing {
    height: 8px;
}

.library table th {
    padding: 0 4px;
    text-align: center;
    font-weight: bold;
}

.library table th.subtable {
    padding: 0 0 0 40px;
    text-align: left;
    font-style: italic;
}

.library table td.numeric {
    text-align: center;
}

.library table td.pad-right {
    padding-right: 10px;
}

.library > div .my-button {
    margin-right: 10px;
}

.library > div .my-button.on {
    box-shadow: 2px 2px 10px #111177;
}

.library > section.links a {
    margin-bottom: 4px;
    font-size: 1.4rem;
}

.App .primary-content .library-display h1 {
    margin-bottom: 6px;
}

.primary-content .library-display h2.subtitle {
    font-size: 1.8rem;
}

.App .primary-content .library-display header p {
    max-width: 75%;
    margin-bottom: 12px;
    font-size: 1.6rem;
}

.App .primary-content .library-display p,
.library .bundles p {
    margin-bottom: 12px;
    font-size: 1.4rem;
}

.App .primary-content .library-display li {
    margin-bottom: 2px;
    font-size: 1.4rem;
    line-height: 1.2;
}

div.primary-content.library section ul {
    margin-bottom: 20px;
}

div.primary-content.library section ul li {
    margin-bottom: 2px;
}

div.primary-content.library div.filters select,
div.primary-content.library div.filters .my-button {
    margin-bottom: 10px;
}

div.primary-content.library div.filters .checkbox-pair {
    margin-bottom: 6px;
    font-size: 1.4rem;
}

div.primary-content.library div.filters .num-input {
    align-items: center;
    font-size: 1.4rem;
}

div.primary-content.library div.filters .num-input label {
    margin-right: 6px;
    position: relative;
    top: -2px;
}

div.primary-content.library div.filters .short {
    width: 54px;
}

div.primary-content.library div.filters input[type="checkbox"] {
    position: relative;
    top: 2px;
}