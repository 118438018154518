.App > footer {
    height: 20px;
    background-color: #eeeeee;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.App > footer > div {
    align-items: center;
}

.App > footer p {
    margin-right: 4px;
    font-size: 1rem;
}

.App > footer img {
    height: 20px;
    width: auto;
}