
ul {
    list-style: none;
}

.space-between {
    width: 100%;
    justify-content: space-between;
}

div.char-sheet div.float-right {
    align-items: flex-end;
}

div.char-sheet > div.float-right .my-button {
    margin-left: 12px;
    margin-bottom: 12px;
}

.parchment {
    margin: 20px 0 18px;
    width: 100%;
    border-radius: 12px 0 12px 12px;
    background-color: #d2b48c;
    background-image: url(../media/paper-texture.png);
    box-shadow: 1px 1px 5px #111111;
    position: relative;
}

.parchment .char-sheet-tabs {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -25px;
    right: -1px;
}

.parchment .char-sheet-tabs .one-tab {
    margin: 0 1px;
    padding: 4px 10px 5px;
    border-radius: 12px 12px 0 0;
    background-color: #bfa480;
    box-shadow: 1px -1px 1px #111111;
    font-size: 1.6rem;
    cursor: pointer;
}

.parchment .char-sheet-tabs .one-tab.active {
    background-color: #d2b48c;
    background-image: url(../media/paper-texture.png);
}

.parchment .parchment-padding {
    padding: 14px 6% 24px;
}

.parchment .meb-contain-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.parchment .editable-text {
    text-shadow: 1px 1px 4px rgba(17, 17, 148, 0.4);
    cursor: pointer;
}

.parchment .meb-popout-edit {
    display: none;
}

.parchment .meb-popout-edit.meb-open {
    padding: 0 1px;
    display: flex;
    background-color: #666666;
    border: 2px solid #444444;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}

.parchment .meb-popout-edit.rows.meb-open {
    flex-direction: column;
    align-items: flex-end;
}

.parchment .meb-popout-edit.meb-open input {
    width: 120px;
    margin-right: 2px;
}

.parchment header header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.parchment h1 {
    font-style: italic;
    margin-bottom: 6px;
}

.parchment h2 {
    font-size: 2rem;
}

.parchment .stat {
    font-size: 1.4rem;
}

.parchment header header .space-between {
    align-items: center;
}

.App .parchment h2.subtitle {
    margin-bottom: 0;
    display: flex;
}

.parchment h2.subtitle span.float-left {
    margin-right: 6px;
}

.parchment .xp-summary {
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.parchment .paragraph {
    margin-top: 8px;
    font-size: 1.4rem;
}

.parchment header header .stat.float-right,
.parchment .xp-button.float-right {
    margin-left: 20px;
}

.parchment header section.good-save {
    align-items: center;
}

.parchment .good-save .my-button {
    margin: 0 2px 14px;
}

.parchment .good-save .my-button.selected,
.parchment .build-library .my-button.selected {
    box-shadow: 2px 2px 10px #111177;
}

.parchment header section.top-breakdowns {
    justify-content: space-around;
    align-items: center;
    flex-grow: 2;
}

.parchment .breakdown.columns {
    margin-bottom: 14px;
    align-items: flex-start;
}

.App .parchment p.big-num,
.App .parchment .equals-symbol,
.App .parchment .plus-symbol {
    font-size: 2rem;
    font-weight: bold;
}

.App .parchment .equals-symbol,
.App .parchment .plus-symbol {
    position: relative;
    top: 1px;
}

.parchment p.trailing-num {
    margin-left: 6px;
}

.parchment .equals-symbol,
.parchment .plus-symbol {
    position: relative;
    top: -2px;
}

.parchment .fill-in p {
    text-align: center;
}

.parchment .fill-in p.big-num {
    border-bottom: 1px solid brown;
}

.parchment .fill-in p.caption {
    font-size: 1.2rem;
}

.parchment header div.pools-n-general {
    flex-grow: 2;
    align-items: stretch;
}

.parchment header section.pools {
    margin-bottom: 26px;
    justify-content: flex-start;
}

.parchment section.pools > div {
    margin-right: 10%;
    width: 70px;
    height: 80px;
    border-radius: 8px;
    border: 1px solid brown;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parchment section.pools > div .pool-max {
    margin: 3px 0 4px;
    font-size: 1.2rem;
}

.parchment section.pools > div .bar {
    width: 100%;
    height: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #444444;
}

.parchment section.pools > div .bar .inner-bar {
    height: 3px;
    /* display: flex; */
}

.parchment section.pools > div.vp .bar .inner-bar {
    background-color: green;
}

.parchment section.pools > div.rp .bar .inner-bar {
    background-color: darkred;
}

.parchment section.pools > div.mp .bar .inner-bar {
    background-color: #2ab7de;
}

.parchment section.pools > div div.main-pool-val {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parchment section.pools > div div.main-pool-val .above-big-num {
    flex-grow: 1;
}

.parchment section.pools > div div.main-pool-val .below-big-num {
    flex-grow: 1.4;
}

.parchment section.pools > div div.main-pool-val p.small,
.parchment section.play-equipment header p.small {
    font-size: 1rem;
}

.parchment section.pools .meb-contain-edit form.rows {
    align-items: center;
}

.parchment section.pools .meb-contain-edit label {
    margin-bottom: 2px;
    color: #bbbbbb;
}

.parchment section.general-rolls > div.general-general,
.parchment section.skill-rolls div.skills-col-1 {
    margin-right: 12px;
    border-right: 1px solid brown;
    padding-right: 12px;
}

.parchment section.general-rolls > div.saving-throws,
.parchment section.skill-rolls div.skills-col-2 {
    margin-right: 20px;
}

.parchment section.general-rolls div.av-display {
    margin: 4px 12px 12px 0;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.parchment section.general-rolls > div > div.my-button,
.parchment section.skill-rolls div > div.my-button {
    margin-bottom: 12px;
}

.parchment section.general-rolls > div > div.my-button span,
.parchment section.skill-rolls div > div.my-button span {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
}

.parchment section.general-rolls > div > div.my-button img,
.parchment section.skill-rolls div > div.my-button img {
    margin-right: 6px;
    width: 14px;
    opacity: 0.5;
}

.parchment header section.conditions {
    flex-grow: 0.7;
    border-left: 1px solid brown;
    padding-left: 24px;
}

.parchment header section.conditions div.die-mode {
    margin-bottom: 10px;
    border-bottom: 1px solid brown;
    padding-bottom: 12px;
}

.parchment header section.conditions div.die-mode select {
    margin-bottom: 4px;
    font-size: 1.2rem;
}

.parchment header section.conditions div.die-mode div input,
.parchment header section.conditions li input {
    position: relative;
    top: 2px;
}

.parchment section.conditions ul.not-last {
    margin-bottom: 12px;
    border-bottom: 1px dashed brown;
    padding-bottom: 12px;
}

.parchment header section.conditions div.die-mode .checkbox-pair,
.parchment section.conditions li.checkbox-pair span {
    font-size: 1.4rem;
    margin-bottom: 2px;
}

.parchment section.play-equipment input.short {
    width: 60px;
}

.parchment section.play-equipment header.columns {
    margin-bottom: 8px;
    align-items: center;
}

.parchment section.play-equipment header div.rows {
    margin-right: 18px;
    align-items: center;
}

.parchment section.play-equipment div.float-right {
    margin-bottom: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.parchment section.play-equipment .accordion .toggles.open {
    padding-top: 8px;
}

.parchment section.play-equipment .accordion div.float-right {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.parchment section.play-equipment .float-right div.my-button,
.parchment section.play-equipment .float-right input {
    margin-left: 10px;
}

section.play-equipment section.inventory-display {
    margin-bottom: 14px;
}

section.play-equipment section.inventory-display h4 {
    width: 100%;
    padding: 4px 4%;
}

section.play-equipment section.inventory-display h4 span.name-qty {
    display: inline-block;
    width: 54%;
}

section.play-equipment section.inventory-display h4 span.name-qty img {
    width: 14px;
    margin-right: 2px;
}

section.play-equipment section.inventory-display h4 span.bulk {
    display: inline-block;
    width: 20%;
}

section.play-equipment section.inventory-display h4 span.price {
    display: inline-block;
    width: 13%;
}

section.play-equipment section.inventory-display h4 span.up-down {
    width: 13%;
    display: inline-block;
}

section.play-equipment section.inventory-display h4 span.up-down span {
    display: flex;
    justify-content: center;
}

section.play-equipment section.inventory-display h4 span.up-down button {
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
}

section.play-equipment section.inventory-display h4 span.up-down img {
    width: 14px;
    margin-left: 1px;
}

section.play-equipment section.inventory-display h4 {
    width: 100%;
    padding: 4px 6%;
    font-size: 1.4rem;
    font-weight: bold;
}

section.play-equipment section.inventory-display h4 span.bulk.total {
    position: relative;
    left: -37px;
    line-height: 1.2;
}

section.play-equipment section.inventory-display .accordion label {
    margin: 0 4px 2px 0;
    font-size: 1.2rem;
    font-weight: bold;
}

section.play-equipment section.inventory-display .accordion input {
    margin-bottom: 6px;
    width: 50%;
}

section.play-equipment section.inventory-display .accordion textarea {
    margin-bottom: 6px;
}

section.play-equipment section.inventory-display .accordion .manage-item-rituals .preview-link {
    cursor: pointer;
}

section.play-equipment section.inventory-display .accordion .manage-item-rituals select {
    margin-right: 8px;
}

section.play-equipment section.acquiring-center select,
section.play-equipment section.acquiring-center div.my-button {
    margin-right: 8px;
}

section.configure-skills {
    margin-bottom: 16px;
}

section.configure-skills .trained {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
}

section.configure-skills .trained label,
section.configure-skills section.synergies label {
    margin-right: 10px;
    font-size: 1.4rem;
    font-weight: bold;
}

section.configure-skills .trained select {
    margin-right: 6px;
    position: relative;
    top: 1px;
}

section.configure-skills .ranks-assignment {
    margin-bottom: 12px;
}

section.configure-skills .ranks-assignment table {
    margin-bottom: 6px;
}

section.configure-skills .ranks-assignment th,
section.configure-skills .ranks-assignment > label {
    margin-left: 2px;
    padding-right: 6px;
    font-size: 1.1rem;
    font-weight: bold;
}

.parchment header .checkbox-pair input {
    position: relative;
    top: 2px;
}

.parchment header .checkbox-pair label {
    font-size: 1.4rem;
}

section.configure-skills .ranks-assignment td select,
section.configure-skills .ranks-assignment > select,
section.configure-skills section.synergies select,
section.configure-skills section.synergies div div label {
    font-size: 1.2rem;
}

section.configure-skills .net-ranks-display table {
    margin-bottom: 12px;
    border-collapse: separate;
    border-spacing: 4px 4px;
    font-size: 1.1rem;
}

section.configure-skills .net-ranks-display th {
    font-weight: bold;
}

section.configure-skills .net-ranks-display td {
    text-align: center;
}

section.configure-skills section.synergies > div {
    margin-top: 6px;
    flex-wrap: wrap;
    align-items: center;
}

section.configure-skills section.synergies > div div.rows {
    margin-right: 6px;
}

section.skill-rolls h2 {
    text-align: center;
}

section.configure-specials > div.rows > div.columns {
    flex-wrap: wrap;
}

section.configure-specials label,
form.build-library label,
.build-items label {
    margin-bottom: 4px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.2;
}

section.configure-specials header.search-parameters {
    margin-bottom: 12px;
    justify-content: center;
    flex-wrap: wrap;
}

section.configure-specials > div.rows > div.columns > div {
    margin: 0 8px 8px 0;
    padding: 6px 3%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid brown;
    border-radius: 6px;
}

section.configure-specials > div.rows > div.columns > div select {
    margin-bottom: 6px;
}

section.configure-specials > div.rows > div.columns div.select-kit > select:first-child.color-coded {
    color: darkred;
    font-weight: bold;
}

section.configure-specials > div.rows > div.columns div.select-feat select.color-coded {
    color: #2ab7de;
    font-weight: bold;
}

section.configure-specials > div.rows > div.columns div.select-talent select.color-coded {
    color: green;
    font-weight: bold;
}

section.configure-specials .radio-bank {
    margin: 0 6px 8px 0;
    font-size: 1.2rem;
}

section.configure-specials .radio-bank input {
    position: relative;
    top: 2px;
}

section.configure-specials .radio-bank input.strangely-already-low-enough {
    position: relative;
    top: -3px;
}

section.configure-specials div.select-kit .selects.columns {
    align-items: flex-end;
}

section.configure-specials div.select-kit .selects.columns > div,
section.configure-specials .search-parameters .checkbox-pair {
    margin-right: 6px;
}

section.configure-specials .search-parameters .checkbox-pair input {
    position: relative;
    top: 2px;
    left: 1px;
}

section.configure-specials div.select-kit div.parcels div.checkbox-pair {
    display: flex;
    align-items: center;
}

section.configure-specials div.select-kit div.parcels div.checkbox-pair label {
    font-size: 1.1rem;
    font-weight: normal;
}

.parchment .bio-div {
    width: 100%;
    margin-bottom: 16px;
}

.parchment .bio-div > div {
    width: 90%;
}

header.build-library {
    margin-bottom: 20px;
}

.parchment .build-library > header {
    margin-bottom: 4px;
}

.parchment .build-library .my-button {
    margin-right: 10px;
}

form.build-library div.rows {
    margin-bottom: 10px;
}

form.build-library h3 {
    margin-top: 10px;
}

form.build-library .main-body,
.build-items .main-body {
    flex-grow: 2.5;
}

form.build-library .right-column,
.build-items .right-column {
    margin-left: 20px;
    flex-grow: 1;
}

form.build-library .right-column ul,
.build-items .right-column ul {
    margin-bottom: 14px;
}

form.build-library .right-column input[type="number"] {
    width: 50px;
}

form.build-library > header > div.rows,
.build-items div.rows {
    align-items: flex-start;
}

form.build-library div.rows > ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

form.build-library div.rows > ul,
form.build-library div.rows > div {
    margin-bottom: 6px;
}

form.build-library div.main-body > ul.columns label {
    margin-right: 10px;
}

form.build-library .checkbox-pair input {
    position: relative;
    top: 2px;
}

form.build-library section.various-penalties,
form.build-library section.various-bonuses,
form.build-library section.xp-parcels {
    align-items: flex-start;
}

form.build-library input.small {
    width: 46px;
    margin-right: 4px;
    text-align: right;
}

form.build-library section.various-penalties li label,
form.build-library section.various-bonuses li label {
    margin: 0 8px;
}

form.build-library section.various-penalties .my-button,
form.build-library section.various-bonuses .my-button {
    margin-top: 6px;
}

form.build-library section.bonus-talents,
form.build-library section.bonus-trainings,
form.build-library section.extended-rests,
form.build-library section.passives,
form.build-library section.selective-passives,
form.build-library.build-talent section.various-bonuses,
/* form.build-library.build-talent section.various-penalties, */
form.build-library.build-talent section.standard-actions,
form.build-library.build-talent section.swift-actions,
form.build-library.build-talent section.short-rest-actions,
form.build-library.build-talent section.extended-rest-actions,
form.build-library section.brown-box,
form.build-items section.brown-box {
    margin: 12px 12px 12px 0;
    padding: 0 3% 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid brown;
    border-radius: 6px;
}

form.build-library section.brown-box.attack-form input[type="number"] {
    width: 46px;
}

form.build-library section.brown-box.attack-form .buffer-right {
    margin-right: 6px;
}

form.build-library section.passives textarea,
form.build-library section.selective-passives textarea,
form.build-library section.selective-passives .checkbox-pair,
/* form.build-library.build-talent section.various-penalties textarea, */
form.build-library.build-talent section.various-bonuses textarea,
form.build-library.build-talent section.standard-actions textarea,
form.build-library.build-talent section.swift-actions textarea,
form.build-library.build-talent section.short-rest-actions textarea,
form.build-library.build-talent section.extended-rest-actions textarea,
form.build-library section.brown-box textarea,
form.build-items section.brown-box textarea {
    margin-bottom: 6px;
}

form.build-library section.bonus-talents > div.columns,
form.build-library section.bonus-trainings > div.rows {
    margin-bottom: 8px;
    width: 100%;
    flex-wrap: wrap;
}

form.build-library section.bonus-talents > div.columns > div.columns {
    width: 33%;
    align-items: center;
}

form.build-library section.bonus-trainings > div.rows > div.columns {
    width: 80%;
    align-items: center;
}

form.build-library section.bonus-talents > div > div label,
form.build-library section.bonus-trainings label {
    margin-right: 6px;
}

form.build-library section.extended-rests textarea,
form.build-library section.xp-parcels textarea,
form.build-library section.passives textarea {
    margin-bottom: 8px;
}

form.build-library section.xp-parcels .my-button {
    margin-bottom: 22px;
}

form.build-items > div.columns,
form.build-items section.attack-form > div.columns {
    flex-wrap: wrap;
}

.build-items input[type="text"],
.build-items input[type="number"],
.build-items select,
.build-items textarea {
    margin-bottom: 10px;
}

.build-items input[type="checkbox"] {
    position: relative;
    top: 1px;
}

.build-items ul input[type="checkbox"] {
    position: relative;
    top: 2px;
}

.build-items input.medium {
    margin-right: 12px;
    width: 80px;
}

.build-items input.short {
    width: 46px;
}

.build-items input.short.s {
    margin-right: 8px;
}

div.play-accordions {
    padding: 14px 0 14px 8%;
    flex-grow: 1;
}

div.play-accordions h3 {
    margin-left: 6%;
}

div.play-accordions h4.default-gray {
    color: #666666;
}