.App > header {
    width: 100%;
    /* position: relative; */
    border-radius: 12px 12px 0 0;
    background-image: url('../media/banner.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    background-color: #444444;
}

.App > header .banner-contents {
    padding: calc(261 / 1088 * 20%) 4%;
    justify-content: space-between;
    align-items: center;
}

.App > header .banner-contents h1 {
    border-radius: 8px;
    font-size: 3rem;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 6px rgba(255, 255, 255, 0.5);
    text-shadow: 1px 1px 8px white;
}

.App > header .banner-contents .top-right {
    display: flex;
    flex-direction: row-reverse;
}

.App > header .banner-contents .nav-dropdown-system,
.App > header .banner-contents .profile {
    height: 50px;
    width: calc(50px + 14px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
}

.App > header .banner-contents .profile img {
    margin-left: 14px;
}

.App > header .banner-contents img.nav-toggle,
.App > header .banner-contents .profile img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #aaaaaa;
    cursor: pointer;
    z-index: 1;
}

.App > header .nav-dropdown-system nav,
.App > header .profile nav {
    position: absolute;
    top: 46px;
    right: -8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: #aaaaaa;
    border: 2px solid #444444;
    border-radius: 6px;
    padding: 4px 0;
    z-index: 3;
}

.App > header .nav-dropdown-system nav a,
.App > header .profile nav a,
.App > header .profile.signed-in nav p {
    padding: 4px 12px;
    font-size: 1.2rem;
    text-align: center;
}

.App > header .nav-dropdown-system nav a,
.App > header .profile nav a,
.App > header .profile.signed-in nav p.logout {
    cursor: pointer;
}

.App > header .dummy-space {
    width: 100%;
    height: 0;
    padding-top: calc(261 / 1088 * 60% - 50px);
}