.columns {
    display: flex;
    flex-direction: row;
}

.rows {
    display: flex;
    flex-direction: column;
}

.my-button {
    margin-bottom: 2px;
    padding: 0;
    border: none;
    background-color: #91a6c7;
    border-radius: 10px;
    box-shadow: 2px 2px 6px #111111;
    cursor: pointer;
}

.my-button a,
.my-button span {
    display: flex;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #eeeeee;
}

.my-button.external-link a img {
    margin-left: 8px;
    width: 16px;
}

.or {
    font-weight: bold;
    color: darkred;
}

.App {
    width: 92%;
    max-width: 1100px;
    height: calc(100vh - 8px);
    margin: 4px auto;
    border-radius: 12px;
    background-color: #bac9e0;
    box-shadow: 0 0 8px #111111;
    overflow: hidden;
}

.App .main-envelope .contents {
    width: 70%;
    overflow-y: auto;
}

.App .main-envelope .sidebar {
    width: 30%;
    background-color: #aaaaaa;
}

.App .content-padding {
    padding: 20px 4%;
}

.App .sidebar-padding {
    padding: 6px 2%;
}

.App .primary-content > section {
    margin-bottom: 14px;
}

.App .primary-content h1 {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 12px;
}

.App .primary-content h2 {
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.App .primary-content h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.App .primary-content p,
.user-management-table {
    font-size: 1.4rem;
    line-height: 1.2;
}

.home-page,
.char-library > section,
.manage-campaigns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.char-library section section.not-last {
    margin-bottom: 14px;
}

.char-library p {
    margin-bottom: 3px;
}

section.settings-button-bank {
    margin-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
}

section.settings-button-bank .my-button {
    margin-right: 10px;
    margin-bottom: 6px;
}

.manage-campaigns section {
    width: calc(60% + 20px);
    margin-bottom: 20px;
    padding: 10px 10%;
    align-items: flex-start;
}

.manage-campaigns section.one-campaign {
    border: 1px solid gray;
    box-shadow: 2px 2px 6px gray;
}

.manage-campaigns section form select {
    margin-right: 8px;
}

.manage-campaigns section ul {
    width: 100%;
    margin-top: 10px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.manage-campaigns section li {
    margin-right: 20px;
}

section.user-management {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.user-management-table {
    margin-bottom: 10px;
    border: 1px solid gray;
}

.user-management-table th {
    padding: 4px 20px 7px 0;
    font-weight: bold;
}

.user-management-table tr {
    margin-bottom: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid gray;
}

.user-management-table td {
    padding: 0 20px 7px 0;
}

.user-management-table td .meb-contain-popout {
    width: 70px;
    height: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
}

.user-management-table td .meb-contain-popout .popout {
    display: none;
}

.user-management-table td .meb-contain-popout .popout.open {
    display: flex;
    position: absolute;
    top: calc(100% + 6px);
    left: -6px;
    background-color: #666666;
    border: 2px solid #444444;
    border-radius: 2px;
    z-index: 2;
}

.user-management-table td .meb-contain-popout .popout.open button {
    margin: 0 1px;
}

.App form.login-form,
.App form.new-char-form {
    align-items: flex-start;
}

.App form.login-form > div,
.App form.new-char-form > div,
.manage-campaigns section.new-campaign form > div {
    margin-bottom: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.App form.login-form > div label,
.manage-campaigns section form div label {
    margin-bottom: 2px;
    font-size: 1.4rem;
}

.App form.login-form .buffer-above,
.App form.new-char-form .buffer-above {
    margin-top: 14px;
}

.App form.login-form p,
.App form.new-char-form p {
    font-size: 1.2rem;
}

.App form.login-form p.error-message,
.App form.new-char-form p.error-message {
    font-weight: bold;
    color: #990000;
}

.App form.new-char-form {
    width: 84%;
}

.App form.new-char-form > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.App form.new-char-form > div label {
    margin-bottom: 4px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.2;
}

.App form.new-char-form > div.columns {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.new-char-form ul {
    list-style: none;
}

.new-char-form ul li {
    font-size: 1.4rem;
}

.new-char-form ul li input {
    position: relative;
    top: 2px;
}

.home-page .my-button {
    margin-bottom: 12px;
}

.admin-settings .buttons-bank {
    margin-bottom: 16px;
}

.admin-settings .buttons-bank .my-button {
    margin-right: 10px;
}

.code-404 h1 {
    line-height: 1.2;
}

.accordion {
    margin-bottom: 8px;
    border-radius: 12px;
    overflow-y: hidden;
    box-shadow: 1px 1px 8px #444444;
}

.accordion .title {
    background-color: #91a6c7;
}

.accordion h4 {
    width: 100%;
    padding: 4px 6%;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
}

div.accordion > div > div.toggles {
    max-height: 0;
    overflow-y: hidden;
    padding: 0 6%;
    display: flex;
    background-color: rgba(145, 166, 199, 0.4);
    transition: max-height 0.6s ease-out, padding-top 0.6s ease-out, padding-bottom 0.6s ease-out;
}

div.accordion > div > div.toggles.open {
    height: auto;
    max-height: 150px;
    padding: 6px 6%;
    overflow-y: auto;
}

div.accordion > div > div.toggles.open p {
    margin-bottom: 8px;
}

.rules .float-right {
    display: flex;
    justify-content: flex-end;
}

.rules ul.bullets {
    margin: 8px 0 0 26px;
    list-style: disc outside none;
}

.rules li {
    margin-bottom: 6px;
    font-size: 1.4rem;
}

.App .primary-content.rules p {
    margin-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.2;
}

.rules table {
    margin-bottom: 10px;
    font-size: 1.4rem;
    border: 1px solid #444444;
}

.rules h3 {
    margin-top: 12px;
}

.rules tr {
    border-bottom: 1px solid #444444;
}

.rules th {
    padding: 8px;
    font-weight: bold;
    line-height: 1.1;
}

.rules td {
    padding: 4px 6px 8px 6px;
    line-height: 1.1;
}