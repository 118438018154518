section.rolls-outer-window {
    padding: 8px 0 8px 2%;
}

section.rolls-outer-window h2 {
    margin-bottom: 16px;
    font-size: 1.8rem;
    font-weight: bold;
}

section.rolls-outer-window div.rolls-inner-window {
    margin-bottom: 10px;
    height: 220px;
    overflow-y: auto;
}

section.rolls-outer-window div.float-right {
    display: flex;
    justify-content: flex-end;
}

div.roll-display {
    width: 100%;
    margin-bottom: 14px;
    padding: 6px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.roll-display.wealth {
    padding: 6px 26px;
}

div.roll-display h3 {
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
}

div.roll-display.wealth div.columns {
    flex-wrap: wrap;
}

div.roll-display .roll-result {
    align-items: center;
    position: relative;
}

div.roll-display .roll-result .equals {
    position: absolute;
    left: -20px;
}

div.roll-display p.big-num.bg {
    margin: 0 2px;
    width: 24px;
    height: 24px;
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

div.roll-display.d20 p.big-num,
div.roll-display.wealth p.big-num {
    margin: 0 2px;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

div.roll-display.d20 p.big-num.bg::after {
    content: "";
    background-image: url('../media/d20-icon.png');
    background-size: cover;
    opacity: 0.2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

div.roll-display.wealth p.big-num.bg::after {
    content: "";
    background-image: url('../media/d6-icon.png');
    background-size: cover;
    opacity: 0.2;
    position: absolute;
    top: -6px;
    bottom: 6px;
    left: -6px;
    right: 6px;
}